<script setup>
import LayoutContainer from "../layout/LayoutContainer.vue"
import {OneButton} from "@jirizavadil/framework.one/ui"
import sink from "@/sink/index.js"

const props = defineProps({
  content: Object,
})

function onClickCTA() {
  window.location.href = props.content.cta.href
  sink.event("BannerCTAClick")
}
</script>

<template>
  <LayoutContainer>
    <div class="rounded-3xl bg-neutral-50 dark:bg-neutral-900 px-6 py-10">
      <!-- top -->
      <!--<div class="mt-10 flex flex-row items-center justify-between">
        <span class="text-4xl font-bold">askbot</span>
      </div>-->

      <!-- title -->
      <div class="max-w-2xl">
        <h2 class="whitespace-pre-wrap text-3xl leading-10 md:text-5xl md:leading-[3.75rem]">
          {{ content.title }}
        </h2>
        <p class="mt-3 max-w-lg text-lg text-neutral-500 md:mt-6 md:text-xl">
          {{ content.subtitle }}
        </p>
      </div>

      <!-- cta -->
      <div class="mt-12">
        <OneButton
          :label="content.cta.label"
          :size="14"
          iconEnd="ArrowRight"
          :iconStrokeWidth="3"
          iconAnimate
          @action="onClickCTA()"
        />

        <!--<span class="mt-2 inline-block pl-7"
          ><span
            ><span class="text-neutral-400">{{ content.cta2or }}</span
            >&nbsp;<span class=""> {{ content.cta2 }}</span></span
          ></span
        >-->
      </div>
    </div>
  </LayoutContainer>
</template>
