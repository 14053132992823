const content = {
  en: {
    global: {},
    landing: {
      sections: {
        hero: {
          contributeLabel: "contact",
          toolbarCTA: {
            label: "contact",
            href: "mailto:kristina@melsova.com",
          },
          title:
            'Offload your work\n& get more leads<span class="bg-success-400 px-2 font-bold dark:text-neutral-900">using&nbsp;AI</span>',
          subtitle:
            "Finally a chatbot your users want to talk to.\nPowered by your knowledge base & our AI.",
          cta: {
            label: "request demo",
            href: "mailto:kristina@melsova.com",
          },
          // cta2or: "or",
          // cta2: {
          //   label: "try a demo store",
          //   href: "https://demo.qasima.ae",
          // },
          bubbles: [
            {
              id: 1,
              question: "How do I setup my company in Saudi?",
              topic: "company set up",
            },
            {
              id: 2,
              question: "Is this accelerator a good fit for my fintech startup?",
              topic: "accelerator",
            },
            {
              id: 3,
              question: "How much is your co-working space for a team of 3?",
              topic: "company set up",
            },
            {
              id: 4,
              question: "Is the UAE a good place to have a startup?",
              topic: "accelerator",
            },
            {
              id: 5,
              question: "Do I have to pay taxes in the UAE?",
              topic: "finance advisory",
            },
            {
              id: 6,
              question: "What should I take care of when securing funding for my startup?",
              topic: "finance advisory",
            },
          ],
        },
        partners: {
          title: "companies using askbot.ae AI magic",
          list: [
            {
              id: 1,
              logo: "/img/clients/logo-askfounder.svg",
              logoOnDark: "/img/clients/logo-askfounder-ondark.svg",
              class: "w-32 md:w-40",
              url: "https://askfounder.ae",
            },
            {
              id: 2,
              logo: "/img/clients/logo-astrolabs.svg",
              logoOnDark: "/img/clients/logo-astrolabs-ondark.svg",
              class: "w-24 md:w-32 -mt-1",
              url: "https://setupinsaudi.com",
            },
            // {
            //   id: 3,
            //   logo: "/img/partners/partner-bioplastica.svg",
            //   class: "w-28 md:w-36 pt-1",
            //   url: "",
            // },
            // {
            //   id: 4,
            //   logo: "/img/partners/partner-sedlackova.svg",
            //   class: "w-28 md:w-36",
            //   url: "",
            // },
          ],
          cta: {
            label: "join them",
            href: "mailto:kristina@melsova.com",
          },
        },
        why: {
          title: "Why askbot.ae",
          list: [
            {
              id: 1,
              icon: "Unplug",
              title: "Zero development",
              description: "Give us your content, we'll do the rest.",
            },
            {
              id: 2,
              icon: "Handshake",
              title: "Turn web visitors to customers",
              description: "Build trust through sharing useful knowledge.",
            },
            {
              id: 3,
              icon: "Crosshair",
              title: "Be relevant",
              description: "Kill the useless bot, make people engaged & talk.",
            },
            {
              id: 4,
              icon: "Hourglass",
              title: "Save time",
              description: "Offload basic questions, let AI do the job.",
            },
            {
              id: 5,
              icon: "Trophy",
              title: "Stand out",
              description: "Make your AI bot viral - who else has it?",
            },
            // {
            //   id: 6,
            //   icon: "Gift",
            //   title: "Zero cost entry",
            //   description: "First time you pay is when you already made a sale.",
            //   highlighted: true,
            // },
          ],
        },
        ideal: {
          title: "Ideal for",
          mainItem: {
            icon: "Building2",
            title: "Companies",
            description:
              "with an established blog / internal knowledge base, ready to take it to the next level.",
          },
          list: [
            {
              id: 1,
              icon: "LifeBuoy",
              title: "Business consulting",
              description: "setup, finance, legal,&nbsp;...",
            },
            {
              id: 2,
              icon: "Anchor",
              title: "Freezones",
              // description: "Processed immediately or on a specific date.",
            },
            {
              id: 3,
              icon: "Sprout",
              title: "Incubators & accelerators",
              // description: "Funds are transferred to your bank account within 5-7 days.",
            },
            {
              id: 4,
              icon: "Apple",
              title: "Other innovative companies",
              // description: "Verify the gift card codes when customers redeem.",
            },
          ],
        },
        testimonials: {
          list: [
            {
              id: 1,
              quote:
                "Collaborating with askbot.ae to tailor-make our AI chatbot was a great project for us. The team's innovative methods have enabled us to deliver significantly more value to our users, aligning perfectly with our strategic goals",
              avatar: "/img/testimonials/astrolabs-amer-chaar.jpeg",
              name: "Amer Chaar",
              position: "Tech Operations Manager, [AstroLabs](https://astrolabs.com/)",
            },
            // {
            //   id: 2,
            //   quote:
            //     "Qasima's gift card solution was incredibly easy to implement, taking just a few hours, and has required zero maintenance since. It's given our loyal customers more ways to engage with our brand and has noticeably boosted our sales.",
            //   avatar: "/img/testimonials/marketa.jpg",
            //   name: "Marketa",
            //   position: "owner of [Provoni](https://instagram.com/provoni.cz),\nflower boutique",
            // },
          ],
        },
        features: {
          title: "Highlights",
          list: [
            {
              id: 1,
              icon: "Blocks",
              title: "Full service setup",
            },
            {
              id: 2,
              icon: "ToyBrick",
              title: "No development",
            },
            {
              id: 3,
              icon: "Wand2",
              title: "AI powered",
            },
            {
              id: 4,
              icon: "AlarmClock",
              title: "Live in a week",
            },
            {
              id: 5,
              icon: "Blocks",
              title: "On your website",
            },
            {
              id: 6,
              icon: "FileLock",
              title: "You own the\nknowledge base",
            },
            {
              id: 7,
              icon: "LineChart",
              title: "Data insights",
            },
            {
              id: 8,
              icon: "UserRound",
              title: "Hyper-relevant",
            },
          ],
        },
        banner: {
          title: "Offload your work\n& get more leads",
          subtitle:
            "Build trust by letting users talk with your AI chat. Get their questions answered and convert them to customers.",
          cta: {
            label: "request demo",
            href: "mailto:kristina@melsova.com",
          },
        },
        team: {
          title: "Who's behind this",
          list: [
            {
              id: "kristina",
              image: "/img/team/kristina_melsova.jpg",
              name: "Kristina Melsova",
              description:
                "Bootstrapped Founder 🇦🇪 UNWTO startup winner🥇travel | creator economy | e-commerce 📬Start Oops & Ups of Dubai newsletter",
              cta: {
                label: "melsova.com",
                href: "https://melsova.com",
              },
            },
            {
              id: "george",
              image: "/img/team/jiri_zavadil.png",
              name: "Jiri (George) Zavadil",
              description: "ecommerce • branding • ui/ux",
              cta: {
                label: "zavadil.pro",
                href: "https://zavadil.pro",
              },
            },
          ],
        },
        projects: {
          title: "Other projects",
          list: [
            {
              id: "qasima",
              title: "qasima",
              description: "digital gift cards for your customers",
              href: "https://qasima.ae",
            },
            {
              id: "atlaseek",
              title: "atlaseek",
              description: "uniting creators, explorers & unique destinations",
              href: "https://atlaseek.com",
            },
            {
              id: "start-oops-and-ups-of-dubai",
              title: "start oops & ups of Dubai",
              description: "Weekly stories about founder successes & fails",
              href: "https://startoopsandupsofdubai.substack.com",
            },
            {
              id: "platforma-one",
              title: "platforma.one",
              description: "eshops that sell more",
              href: "https://platforma.one",
            },
          ],
        },
        footer: {
          company: "PLGRND LLC-FZ",
          // termsLabel: "terms",
          author: "made in 🇦🇪",
          links: [
            // {id: 1, title: "email", url: "mailto:jiri@zavadil.pro"},
            // {id: 2, title: "x.com", url: "https://x.com/jirizavadil"},
            // {id: 1, title: "instagram", url: "https://instagram.com/qasima.ae", icon: "Instagram"},
            // {id: 4, title: "facebook", url: "https://facebook.com/jirizavadil"},
            // {id: 5, title: "linkedin", url: "https://www.linkedin.com/company/askfounder-ae"},
            // {id: 6, title: "x", url: "https://x.com/askfounder_ae"},
            // {id: 6, title: "github", url: "https://github.com/jirizavadil"},
            // {id: 7, title: "dribbble", url: "https://dribbble.com/jirizavadil"},
          ],
        },
      },
    },
  },
}

const base = {
  locales: [
    {
      id: "en",
      title: "english",
    },
  ],
  content,
}

export default base
