<script setup>
import {computed} from "vue"

const props = defineProps({
  text: String,
})

const segments = computed(() => {
  const regex = /\[([^\]]+)\]\((http[^\s)]+)(?:\s+"([^"]+)")?\)/g
  const output = []
  let lastIndex = 0
  let match

  while ((match = regex.exec(props.text)) !== null) {
    const textBeforeLink = props.text.slice(lastIndex, match.index)
    if (textBeforeLink) output.push({text: textBeforeLink, isLink: false})

    output.push({text: match[1], url: match[2], title: match[3], isLink: true})

    lastIndex = regex.lastIndex
  }

  if (lastIndex < props.text.length) {
    output.push({text: props.text.slice(lastIndex), isLink: false})
  }

  return output
})
</script>

<template>
  <span>
    <template v-for="(segment, index) in segments" :key="index">
      <a
        class="underline transition-all duration-200 ease-in-out hover:text-neutral-800"
        :href="segment.url"
        :title="segment.title"
        target="_blank"
        rel="noopener noreferrer"
        v-if="segment.isLink"
        >{{ segment.text }}</a
      >
      <span v-else>{{ segment.text }}</span>
    </template>
  </span>
</template>
