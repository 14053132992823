// services:
// - facebook pixel
// - google analytics

//     tracked events (using same naming as gtag):
//     kind     | action (pfm)     | action (gtag)    | action (facebook pixel)
// ----------------------------------------------------------------------------
// [ ] app      | screen_view      | screen_view      | PageView
// [ ] app      | download_file    | -                | -

import {logEvent} from "firebase/analytics"
import {analytics} from "../firebase/index.js"

export default {
  event(action, params) {
    console.log("sink event:", action, params)

    // view item
    if (action == "view_item") {
      gte({
        action,
        params: {
          //   items: [
          //     {
          //       item_id: item.sku,
          //       item_name: item.title,
          //       quantity: item.quantity,
          //       price: item.price,
          //     },
          //   ],
        },
      })
    }
    // file downloaded
    else if (action == "download_file") {
      gte({
        action,
        params: {
          fileName: params.fileName,
          fileId: params.fileId,
          buttonLabel: params.buttonLabel,
        },
      })
    } else {
      gte({
        action,
        params,
      })
    }
  },
}

// firebase analytics
// default events reference: https://developers.google.com/gtagjs/reference/ga4-events
// recommended events: https://support.google.com/analytics/answer/9267735?hl=en
// https://developers.google.com/analytics/devguides/collection/gtagjs/events
async function gte(args) {
  if (typeof gtag !== "function") return

  let action = args.action
  let params = args.params

  // console.log(await analytics)
  /* eslint-disable-next-line */ // gtag is global
  //   gtag("event", action, params)
  logEvent(await analytics, action, params)
}
