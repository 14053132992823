import MainView from "../views/MainView.vue"

export const routes = [
  {
    path: "/",
    name: "MainView",
    component: MainView,
    meta: {
      bodyFixed: false,
      requiresAuth: false,
    },
  },
  {
    path: "/terms",
    name: "TermsView",
    component: () => import("../views/TermsView.vue"),
  },
  {path: "", redirect: {name: "MainView"}},
  {path: "/:pathMatch(.*)*", redirect: {name: "MainView"}},
]
