<script setup>
import SectionHero from "../components/sections/SectionHero.vue"
import SectionStats from "../components/sections/SectionStats.vue"
import SectionPartners from "../components/sections/SectionPartners.vue"
import SectionSupporters from "../components/sections/SectionSupporters.vue"
import SectionWhy from "../components/sections/SectionWhy.vue"
import SectionContribute from "../components/sections/SectionContribute.vue"
import SectionIdeal from "../components/sections/SectionIdeal.vue"
import SectionTestimonials from "../components/sections/SectionTestimonials.vue"
import SectionFeatures from "../components/sections/SectionFeatures.vue"
import SectionBanner from "../components/sections/SectionBanner.vue"
import SectionTeam from "../components/sections/SectionTeam.vue"
import SectionProjects from "../components/sections/SectionProjects.vue"
import SectionFooter from "../components/sections/SectionFooter.vue"
import {useLandingStore} from "../stores/landing"
import {storeToRefs} from "pinia"

defineProps({
  content: Object,
})

const landingStore = useLandingStore()

landingStore.bind()
</script>

<template>
  <div class="">
    <SectionHero
      class="min-h-[100svh] sm:min-h-0"
      :content="content.landing.sections.hero"
      :contentGlobal="content.global"
    />
    <!--<SectionStats class="mt-20 md:mt-20" :content="content.landing.sections.stats" />-->
    <SectionPartners class="mt-20 md:mt-32" :content="content.landing.sections.partners" />
    <!--<SectionSupporters
      class="mt-20 md:mt-40"
      :content="content.landing.sections.supporters"
      :contentGlobal="content.global"
    />-->
    <SectionWhy class="md:mt-40" :content="content.landing.sections.why" />
    <!--<SectionContribute
      class="md:mt-40"
      :content="content.landing.sections.contribute"
      :contentGlobal="content.global"
    />-->
    <SectionIdeal class="mt-20 md:mt-40" :content="content.landing.sections.ideal" />
    <SectionTestimonials class="mt-20 md:mt-40" :content="content.landing.sections.testimonials" />
    <SectionFeatures class="mt-20 md:mt-40" :content="content.landing.sections.features" />
    <SectionBanner class="mt-20 md:mt-32" :content="content.landing.sections.banner" />
    <SectionTeam class="md:mt-40" :content="content.landing.sections.team" />
    <SectionProjects class="mt-20 md:mt-32" :content="content.landing.sections.projects" />
    <SectionFooter class="" :content="content.landing.sections.footer" />
  </div>
</template>
