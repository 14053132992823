<script setup>
import {OneButton} from "@jirizavadil/framework.one/ui"
import sink from "@/sink/index.js"

const props = defineProps({
  view: String,
  content: Object,
  contentGlobal: Object,
})

function onClickContact() {
  // const url = props.content.toolbarCTA.href
  // window.open(url, "_blank")

  sink.event("HeaderCTAClick", {
    view: props.view,
  })
}
</script>

<template>
  <!-- top -->
  <div class="flex flex-row items-center justify-between pt-2 md:pt-10">
    <a class="text-2xl font-bold md:text-4xl" href="/">askbot.ae</a>

    <OneButton
      :label="content.toolbarCTA.label"
      kind="plain"
      :size="12"
      iconStart="Send"
      @action="onClickContact()"
      tag="a"
      :href="content.toolbarCTA.href"
    />
  </div>
</template>
